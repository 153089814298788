import { render } from "./Photos.vue?vue&type=template&id=1e6d2862"
import script from "./Photos.ts?vue&type=script&lang=ts"
export * from "./Photos.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1e6d2862"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1e6d2862', script)) {
    api.reload('1e6d2862', script)
  }
  
  module.hot.accept("./Photos.vue?vue&type=template&id=1e6d2862", () => {
    api.rerender('1e6d2862', render)
  })

}

script.__file = "src/views/Main/components/Photos/Photos.vue"

export default script