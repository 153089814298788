import { render } from "./MainPageSearch.vue?vue&type=template&id=656b1082"
import script from "./MainPageSearch.ts?vue&type=script&lang=ts"
export * from "./MainPageSearch.ts?vue&type=script&lang=ts"

import "./mainPageSearch.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "656b1082"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('656b1082', script)) {
    api.reload('656b1082', script)
  }
  
  module.hot.accept("./MainPageSearch.vue?vue&type=template&id=656b1082", () => {
    api.rerender('656b1082', render)
  })

}

script.__file = "src/views/Main/components/MainPageSearch/MainPageSearch.vue"

export default script