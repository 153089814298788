import { render } from "./MainPageBalance.vue?vue&type=template&id=ba8fafa8"
import script from "./MainPageBalance.ts?vue&type=script&lang=ts"
export * from "./MainPageBalance.ts?vue&type=script&lang=ts"

import "./mainPageBalance.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "ba8fafa8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ba8fafa8', script)) {
    api.reload('ba8fafa8', script)
  }
  
  module.hot.accept("./MainPageBalance.vue?vue&type=template&id=ba8fafa8", () => {
    api.rerender('ba8fafa8', render)
  })

}

script.__file = "src/views/Main/components/MainPageBalance/MainPageBalance.vue"

export default script