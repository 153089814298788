import { render } from "./MetaPage.vue?vue&type=template&id=53d7a8c2"
import script from "./MetaPage.ts?vue&type=script&lang=ts"
export * from "./MetaPage.ts?vue&type=script&lang=ts"

import "./metaPage.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "53d7a8c2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('53d7a8c2', script)) {
    api.reload('53d7a8c2', script)
  }
  
  module.hot.accept("./MetaPage.vue?vue&type=template&id=53d7a8c2", () => {
    api.rerender('53d7a8c2', render)
  })

}

script.__file = "src/views/Main/components/MetaPage/MetaPage.vue"

export default script