import { render } from "./IncognitoPopup.vue?vue&type=template&id=7ec13a82"
import script from "./IncognitoPopup.ts?vue&type=script&lang=ts"
export * from "./IncognitoPopup.ts?vue&type=script&lang=ts"

import "./incognitoPopup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7ec13a82"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7ec13a82', script)) {
    api.reload('7ec13a82', script)
  }
  
  module.hot.accept("./IncognitoPopup.vue?vue&type=template&id=7ec13a82", () => {
    api.rerender('7ec13a82', render)
  })

}

script.__file = "src/views/Main/components/IncognitoPopup/IncognitoPopup.vue"

export default script